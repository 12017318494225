.borderGreen {
  border-top: 10px solid #7ee387;
}
.dashboard-overview {
  background-color: #f7f8ff;
  border-radius: 10px;
  overflow: hidden;
}
.balance-card-bg {
  background-image: url("../../Assets/Images/balance.png");
  min-height: 14vw;
  min-width: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 15px 25px;
  background-color: #296b91;
  border-radius: 20px;
}

.debit-card-bg {
  background-image: url("../../Assets/Images/card.png");
  min-height: 14vw;
  min-width: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 15px 25px;
  position: relative;
  border-radius: 20px;
}

.debit-card-bg .card-no-text {
  position: absolute;
  width: 80%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dashboard-card-img {
  width: 100%;
  max-height: 600px;
}
.user-img {
  width: 20px;
  margin-right: 8px;
}
.option {
  width: 30px;
}
.received-button {
  background-color: #16a6004f;
  border: none;
  border-radius: 5px;
  color: #17a600;
  padding: 6px 10px;
}
.receipt-btn {
  background: #f2f2f2;
  padding: 6px 10px;
  color: #707070;
  border: none;
  border-radius: 5px;
}
.dashboard-right {
  background-color: #f7f8ff;
  border-radius: 30px;
  min-height: max-content;
  padding: 24px 20px;
  height: 100%;
}
.dashboard-right-div {
  background-color: #fff;
  min-height: 440px;
  border-radius: 10px;
  overflow: hidden;
}
.saving-card {
  background-color: #f7f8ff;
  border: 1px solid #f7f8ff;
  border-radius: 20px, 0px, 20px, 0px !important;
  padding: 30px;
  width: 100%;
  overflow: hidden;
}

.detail_boxes_div .detail_boxes {
  text-align: center;
  background: hsl(206deg 100% 73.3% / 19%);
  padding: 30px;
  border-radius: 20px;
  border-left: 6px solid #317e9e;
}
.detail_boxes_div .detail_boxes p {
  font-weight: 600;
  font-size: 18px;
}

@media (max-width: 990px) {
  .debit-card-bg {
    min-height: 250px;
  }
  .debit-card-bg .card-no-text {
    width: 80%;
    padding-right: 5vw;
  }
}

@media (max-width: 991px) {
  .dashboard-card-img {
    display: none;
  }
}

@media (max-width: 767px) {
  .programing .common-btn2 {
    width: 100%;
    margin-bottom: 10px;
  }
  .programing .common-btn2.btn-sm {
    max-width: 100px;
  }
}

@media (max-width: 430px) {
  .debit-card-bg .card-no-text {
    width: 80%;
    padding-right: 0vw;
  }
  .debit-card-bg .card-no-text .d-flex {
    display: block !important;
  }
  .debit-card-bg .card-no-text .d-flex span {
    display: block;
  }
}
