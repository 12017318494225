.AuthRight {
  background-color: #317d9d;
}
.pdf-button {
  background-color: #317d9d;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
}
.form-label {
  font-size: 18px;
  font-weight: 600;
}

a {
  text-decoration: none;
}
.AuthRight {
}
.purpose-card {
  cursor: pointer;
  overflow: hidden;
}
.purpose-card p {
  color: #a4a6b5;
}
.purpose-card :hover {
  background-color: #317d9d;
  color: #fff;
}
.purpose-card.active {
  background-color: #317d9d;
  color: #fff;
}
.otpInput input {
  border: 1px solid gray;
  border-radius: 6px;
}
.border-layout {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.otpInput input {
  width: 50px !important;
  padding: 7px !important;
}
.otpInput div {
  justify-content: space-around;
}
.w-20 {
  width: 20%;
}
