@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
a {
  text-decoration: none;
}
.themeText {
  color: #307d9e;
}
.common-margin {
  margin-top: 50px;
}
.cms-heading {
  color: #307d9e;
  text-align: center;
  font-weight: 700;
}
.common-btn {
  background-color: #7ee387;
  padding: 8px 5px;
  border: transparent;
  color: white;
  border-radius: 3px;
}
.selected-payment-method {
  background: #77c4ff30;
  padding: 20px;
  border-radius: 8px;
}
.common-btn2 {
  background-color: #317e9e;
  padding: 5px;
  border: transparent;
  border-radius: 5px;
  color: white;
}
.f-32 {
  font-size: 18px;
  line-height: 24px;
}
.f-48 {
  font-size: 30px;
  line-height: 38px;
}
.f-40 {
  font-size: 30px;
  line-height: 38px;
}
.page-link {
  color: #317e9e !important;
}
.view_password {
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 10px;
}

/* width */
.sidebar-box::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #555;
}

/* Handle */
.sidebar-box::-webkit-scrollbar-thumb {
  background: #9bcfe5;
  border-radius: 10px;
}
.profile-dropdown .dropdown-item:active {
  background-color: transparent;
}
.typeWriter {
  display: inline-flex;
}

.typeWriter span {
  word-break: break-all;
  height: 1.2em;
  width: 0%;
  overflow: hidden;
  animation: t 1s linear infinite alternate;
}

.typeWriter span:before {
  content: " ";
  display: inline-block;
}

@keyframes t {
  90%,
  100% {
    width: 100%;
  }
}
