/* --- Header --- */
.home_header_page {
  background-color: #307d9e;
  border-radius: 12px;
  padding: 10px 40px;
}
.home_header_page .navbar-nav a {
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 600;
}
.home_header_page .home_nav_buttons button {
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
}
.home_header_page .home_nav_buttons .button_1 {
  background-color: #7ee387;
  border-radius: 8px;
  color: #ffffff;
}
.home_header_page .home_nav_buttons .button_2 {
  background-color: #ffffff;
  color: #307d9e;
  border-radius: 8px;
}
.home_header_page .home_nav_buttons .language_header_dropdown {
  background-color: #ffffff;
  border-radius: 8px;
}

/* --- Banner --- */

.home_banner_page {
  background: #f4f5fc;
  padding: 20px;
  border-radius: 12px;
}
.home_banner_page .banner_page_content h2 {
  font-size: 50px;
  font-weight: 700;
  color: #307d9e;
}
.home_banner_page .banner_page_content h2 span {
  color: #7ee387;
}
.home_banner_page .banner_page_content p {
  font-size: 12px;
}
.home_banner_page .banner_page_content .banner_content_buttons button {
  font-size: 16px;
  font-weight: 600;
  padding: 12px 30px;
  border-radius: 12px;
}
.home_banner_page .banner_page_content .banner_content_buttons .button_1 {
  background-color: #307d9e;
  color: #ffffff;
}
.home_banner_page .banner_page_content .banner_content_buttons .button_2 {
  background-color: #ebebeb;
}

/* --- Features --- */

.home_features_page h1 {
  color: #307d9e;
  font-size: 64px;
  font-weight: 800;
}
.home_features_page h1 span {
  color: #7ee387;
}
.home_features_page p {
  line-height: 35px;
}

/* --- Services --- */

.home_services_page h2 {
  color: #307d9e;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
}
.home_services_page .service_content_container {
  text-align: center;
  border-radius: 17px;
  border: 1px solid #317d9d80;
  padding: 20px;
}
.home_services_page .service_content_container img {
  border: 1px solid #e2e2ec;
  border-radius: 10px;
  padding: 10px;
  background-color: #f7f8ff;
}
.home_services_page .service_content_container h6 {
  color: #307d9e;
  font-size: 18px;
  font-weight: 600;
}
.home_services_page .service_content_container p {
  color: #a4a6b5;
}

/* --- Buy Tokens --- */

.home_buy_tokens {
  background-color: #f4f5fc;
  border-radius: 12px;
  position: relative;
}
.home_buy_tokens h2 {
  font-size: 64px;
  color: #307d9e;
  font-weight: 700;
}
.home_buy_tokens h2 span {
  color: #7ee387;
}
.buy_token_button {
  position: absolute;
  bottom: 40px;
  right: 40px;
}
.buy_token_button button {
  background-color: #7ee387;
  color: #ffffff;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 25px;
  font-weight: 600;
}

/* --- Contact --- */

.home_contact_page {
  background-color: #307d9e;
  position: relative;
}
.home_contact_page img {
  position: absolute;
  bottom: 0;
  left: 33%;
  transform: translate(-50%, 0);
}
.home_contact_page h1 {
  text-transform: uppercase;
  font-size: 128px;
  font-weight: 900;
  color: #ffffff;
}
.home_contact_page form {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}
.home_contact_page form label {
  margin-bottom: 6px;
  font-weight: 500;
}
.home_contact_page form button {
  background-color: #213638;
  color: #ffffff;
  border-radius: 8px;
}

/* --- FAQ --- */

.home_faq_page .home_faq_heading {
  color: #307d9e;
}
.home_faq_page h6 {
  font-size: 36px;
  font-weight: 600;
}
.home_faq_page .FaqSet .accordion-item {
  background: #f9f9f9;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
}
.home_faq_page .FaqSet button.accordion-button {
  background: #f9f9f9;
  color: #307d9e;
  box-shadow: 0 0 0;
  line-height: 24px;
}
.home_faq_page .FaqSet button.accordion-button.collapsed {
  color: #000;
}
.home_faq_page .FaqSet button.accordion-button:focus {
  box-shadow: 0 0 0;
}
.home_faq_page .FaqSet .accordion-button:not(.collapsed)::after {
  background-image: url("../../Assets/Images/minu.png");
}
.home_faq_page .FaqSet .accordion-button::after {
  background-image: url("../../Assets/Images/plus.png");
}

/* --- Footer --- */

.home_footer_page {
  background-color: #307d9e;
  color: #ffffff;
}
.home_footer_page .footer_link_content {
  width: 70%;
}
.home_footer_page .footer_link_content ul {
  list-style-type: none;
  line-height: 30px;
}
