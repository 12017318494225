.profileAvatarDiv {
  justify-content: center;
  display: flex;
}

.profileChangePencil {
  width: 32px;
  height: 32px;
  background-color: #307d9e;
  border-radius: 50%;
  bottom: 0;
  color: #fff;
  right: 0px;
}
.userImg {
  width: 148px;
  height: 148px;
  border-radius: 50%;
}
.userImg::after {
  content: "";
  display: block;
  width: 156px;
  height: 156px;
  border: 2px solid #307d9e;
  border-radius: 50%;
  position: absolute;
  left: -5px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.userImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
