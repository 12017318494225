.custom-table {
  overflow-x: scroll;
}
.custom-table {
  overflow-x: scroll;
  background: #f7f8ff;
  padding: 20px;
  border-radius: 15px;
}
.custom-table table tr {
  border-bottom: 8px solid #f7f8ff;
  border-radius: 10px;
}
@media (min-width: 1000px) {
  .layout-right {
    /* margin-left: 50px; */
    /* margin-right: 50px; */
    padding-left: 280px;
    padding-right: 0px;
  }
}
.banner_bg {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.banner_bg .banner_img {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  object-fit: cover;
}
.header_text {
  position: relative;
  padding: 4vw 2vw;
}
.header_text h1 {
  font-weight: 600;
  font-size: calc(1vw+1.5rem);
}
.header_text p {
  font-weight: 400;
  font-size: 18px;
}

@media only screen and (min-width: 100px) and (max-width: 1200px) {
  ul.pages-links {
    padding-right: 20px;
  }
  .offcanvas-header a img {
    width: 200px;
  }
  .pages-links {
    padding-left: 0px;
  }
  .offcanvas-header button.btn-close {
    filter: invert(1);
    opacity: 1;
  }
  .layout-right {
    margin-left: 0px;
  }
  .pages-links a {
    border-radius: 35px;
  }
  .pages-links a.active::before,
  .pages-links a.active::after {
    display: none;
  }
  .offcanvas-header a img {
    width: 200px;
  }
}
@media only screen and (min-width: 100px) and (max-width: 991px) {
  .mobile-nav {
    display: none;
  }
}
@media only screen and (min-width: 100px) and (max-width: 800px) {
  a.mobile-logo img {
    max-width: 50px;
  }
  .mobile-header span svg {
    height: 30px;
    width: 30px;
  }
}
@media(min-width:767px){
.bg-box-light{
  background: rgb(243, 245, 246);
}
}