@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* font- families and font sizes css */
.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}
.inter-bold {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.letter-1 {
  letter-spacing: 1px;
}
.letter-2 {
  letter-spacing: 2px;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-family: 15px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}
.f-22 {
  font-size: 22px;
}
@media (max-width: 767px) {
  .f-22 {
    font-size: 18px;
  }
}
.f-24 {
  font-size: 24px;
}
.f-44 {
  font-size: 44px;
}
.cursor-pointer {
  cursor: pointer;
}

/* override css */
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 500 !important;
  font-family: "Montserrat", sans-serif !important;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom: 4px solid #7ee387 !important;
}
.nav-link {
  color: #000 !important;
  opacity: 4px !important;
  font-family: "Montserrat", sans-serif !important;
}

/* button css */
.filter-button {
  border: 1px solid #317d9d;
  padding: 5px 8px;
  border-radius: 5px;
  color: #317d9d;
  background-color: #fff;
}

.cutom-table {
  background-color: #f7f8ff;
  border-radius: 20px;
  padding: 12px;
  overflow: auto;
}
td {
  min-width: 150px;
  margin-bottom: 12px;
}
table {
  border-collapse: collapse;
}
th,
td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}
tbody tr {
  background-color: #fff;
  border-radius: 10px !important;
}
tbody tr:hover {
  background-color: #f5f5f5;
}
th {
  background-color: #307d9e;
  color: white;
}

.card-details-modal .modal-content {
  max-width: 600px;
}
.add-card-frame-modal .modal-content {
  height: 80vh;
}

/* Search Filter */

.search-filter .search-label {
  float: right;
  display: inline-block;
}
.search-filter .sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.date-filter .sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.search-filter .search-box .search-icon {
  font-size: 16px;
  position: absolute;
  left: 12px;
  top: 12px;
  line-height: 38px;
}
.search-filter .search-box .form-control {
  padding-left: 40px;
  border: 1px solid #dee2e6;
}
