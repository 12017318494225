.borderGreen {
  border-top: 10px solid #7ee387;
}
.dashboard-overview {
  background-color: #f7f8ff;
  border-radius: 10px;
  overflow: hidden;
}
.balance-card-bg {
  background-image: url("../../Assets/Images/balance.png");
  min-height: 216px;
  min-width: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 15px 25px;
}
.dashboard-card-img {
  width: 100%;
}
.user-img {
  width: 20px;
  margin-right: 8px;
}
.option {
  width: 30px;
}
.received-button {
  background-color: #16a6004f;
  border: none;
  border-radius: 5px;
  color: #17a600;
  padding: 6px 10px;
}
.receipt-btn {
  background: #f2f2f2;
  padding: 6px 10px;
  color: #707070;
  border: none;
  border-radius: 5px;
}
.traditional-banking-page .dashboard-right {
  background-color: #f7f8ff;
  border-radius: 30px;
  min-height: 1000px;
  padding: 24px 20px;
}
.traditional-banking-page .dashboard-right-div {
  background-color: #fff;
  min-height: 440px;
  border-radius: 10px;
  overflow: hidden;
}
