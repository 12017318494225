.content-area{
  padding-left: 40px;
  padding-right: 20px;
}
ul.pages-links {
  overflow: visible;
  padding-left: 20px;
}
.pages-links a:not(:last-child) {
  margin-bottom: 8px;
}
.pages-links a {
  padding: 16px ;
  display: block;
  border-bottom-left-radius: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: white;
  border-top-left-radius: 24px;
}
.pages-links a.active {
  background: #ffff;
  font-weight: 600;
  color: black;
}
.pages-links li {
  list-style: none;
}
.pages-links a.active::before,
.pages-links a.active::after {
  content: "";
  position: absolute;
  background: #317e9e;
  width: 50px;
  height: 50px;
  right: 0px;
}
.pages-links a.active::before {
  box-shadow: 7px 10px 0px #fff;
  top: -50px;
  border-bottom-right-radius: 25px;
}
.pages-links a.active::after {
  bottom: -50px;
  box-shadow: 10px -6px 0px #fff;
  border-top-right-radius: 25px;
}
.pages-links a svg {
  font-size: 22px;
}
@media (min-width: 1599.98px) {
  .col-xxl-4 {
    width: 25% !important;
  }
  ul.pages-links {
    height: calc(100vh - 600px);
  }
}
@media (min-width: 320px) and (max-width: 1024px) and (orientation: portrait) {
  ul.pages-links {
    height: calc(100vh - 500px);
  }
}
@media (max-width: 1024.98px) {
  input.form-control {
    font-size: 14px;
    /* padding: 7px 10px; */
  }
  .table-responsive table {
    width: 1000px !important;
  }
  .layout-right {
    height: calc(100vh - 62px);
    width: 100%;
  }
}
.sidebar-box {
  background-color: #317d9d;
  height: 100%;
  overflow: auto;
}
.offcanvas.offcanvas-start {
  background-color: #317d9d;
}
.layout-sidebar {
  height: 100vh;
  width: 280px;
}
@media(max-width:1300px){
  .layout-right .col-lg-8 {flex: 0 0 auto;    width: 100%;}
  .layout-right .col-lg-4 {flex: 0 0 auto;    width: 100%;}
}
@media(max-width:767px){
  .content-area{padding-left: 15px;padding-right: 15px;}
}